section.contact-form {
  .paragraphs-container {
    margin: 0 0 100px 0;
  }

  form {
    max-width: 900px;
    margin: auto;
    text-align: center;
    font-size : 14px;

    input,
    textarea {
      margin: 0 0 20px 0;
      border-color: $black;
      padding: 15px;

      &.invalid{
        border-color: $red;
      }
    }

    input[name="name"] {
      width: calc(50% - 10px);
      display: inline-block;
      margin: 0 10px 20px 0;
    }

    input[name="email"] {
      width: calc(50% - 10px);
      display: inline-block;
      margin: 0 0 20px 10px;
    }

    input[type="file"]{
      display: none;
    }

    .upload-button{
      margin-bottom: 20px;
      display: block;
      width: 100%;
      box-sizing: border-box;;
    }



    textarea {
      height: 200px;
    }

    input[type="submit"] {
      padding: 12px 20px;
      background-color: $black;
      color: $white;
      font-weight: bold;
      min-width: 150px;

    
      &:hover {
        background-color: $white;
        color: $black;
      }
    }

    .error{
      color : $red;
      margin: 0 0 25px 0;
    }
  } 


  .success-container{
    background-color: $green;
    margin: auto;
    width : 100%;
    max-width: 280px;
    padding: 0 20px;
    height: 70px;
    display : flex;
    justify-content: center;
    box-sizing: border-box;
  }
  
  .success{
    align-self: center;
      color: $white;
      text-align: center;
      font-size: 14px;
  }
}

@media screen and (max-width: $mobile) {
  section.contact-form {
    .paragraphs-container {
      margin: 0 0 75px 0;
    }

    form {
      input[name="name"],
      input[name="email"] {
        width: 100%;
        display: block; 
        margin: 0 0 20px 0;
      }
    }
  }
}
