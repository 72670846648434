section.banner-large {
  background-repeat: no-repeat;
  background-color: #f0f0f0;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 100px 0;
  background-size: cover;
  background-position: center;
  overflow : hidden;
  text-align : center;
  background-image: url('../../assets/images/sites/placeholders/banner-placeholder.png');

  h1,
  h2 {
    color: $white;
    text-shadow: $black 0px 2px 5px;
    padding : 0 30px; 
    text-align :center;
  }

  button {
    border-color: white;
    font-size: 14px;
    font-weight: bold;

    &:hover {
      border-color: $black;
    }
  }
}

@media screen and (max-width: $mobile) {
  section.banner-large {
    height: 300px;
  }
}
