.pagination {

  align-items: center;
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: center;
  list-style-type: none;
  position: relative;
  margin-top: 30px !important;

  .disabled-page {
    color: #808e9b;
  }

  .pagination-page {
    cursor: pointer;

    &.active {
      border: 1px black solid;
      border-radius: 999em;
    }
  }

  .next,
  .previous {
    width: 80px;
    text-align: center;
  }

  .next {
    margin-left: 20px;

    a:before {
      content: 'next '
    }
  }

  .previous {
    margin-right: 20px;

    a:after {
      content: ' previous'
    }
  }

  .pagination-page {
    font-weight: 700;
    padding-left: 4px;
    padding-right: 4px;
  }

}






section.products-listing {
  position: relative;
  min-height: 400px;

  h3 {
    text-align: center;
    margin: 0 0 100px 0;
  }

  p.error {
    text-align: center;
  }

  .products-list {
    text-align: left;
    font-size: 0;
    margin: auto;
    max-width: 1300px;

    
    a.product-item {
      display: inline-block;
      width: 20%;
      box-sizing: border-box;
      padding: 0 20px;
      margin: 0 0 50px 0;
      text-align: center;
      cursor: pointer;
      text-decoration: none;
      vertical-align: top;

      .image-container {
        position: relative;
        height: 220px;
        margin: 0 0 20px 0;
      }

      img {
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        max-width: 100%;
        max-height: 100%;
      }

      p {
        margin: 8px 0 0 0;
        color: $black;

        &.category {
          font-size: 13px;
          margin: 0 0 0 0;
          color: #6d6d6d;
        }
      }

      h4 {
        font-size: 14px;
        margin: 0 0 3px 0;
        color: $black;
      }

      &.inactive {
        display: none;
      }
    }
  }

  /*Category*/
  .categories-list {
    text-align: center;
    margin: 0 0 80px 0;

    .category-item {
      box-sizing: border-box;
      display: inline-block;
      margin: 0 5px 10px;
      border: 1px solid #000000;
      transition: background 300ms, color 300ms;
      cursor: pointer;

      p {
        padding: 0;
        margin: 0;
        min-width: 100px;
        font-size: 14px;
        font-weight: 700;
      }

      &:hover,
      &.active {
        background: $black;
        color: $white;
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  section.products-listing .products-list {
    a.product-item {
      width: 25%;
    }
  }
}

@media screen and (max-width: $large) {}

@media screen and (max-width: $tablet) {
  section.products-listing .products-list {
    max-width: 800px;

    a.product-item {
      width: 33.333%;
    }
  }
}

@media screen and (max-width: $medium) {
  section.products-listing {
    h3 {
      margin: 0 0 50px 0;
    }

    .categories-list {
      margin: 0 0 50px 0;

      .category-item {
        padding: 0 10px 0;
        margin: 0 5px 10px;
        height: 30px;

        p {
          font-size: 12px;
          min-width: inherit;
          line-height: 26px;
        }
      }
    }

    .products-list {
      max-width: 1200px;
    }
  }
}

@media screen and (max-width: $mobile) {
  section.products-listing .products-list {
    max-width: 600px;

    a.product-item {
      width: 50%;
    }
  }

  .pagination {

    .previous,
    .next {
      width: unset;
    }

    .previous a:after {
      content: unset
    }

    .next a:before {
      content: unset
    }
  }
}

@media screen and (max-width: $small) {
  section.products-listing .products-list {
    max-width: 400px;

    a.product-item {
      width: 100%;

      &:last-child {
        margin: 0;
      }
    }
  }
}