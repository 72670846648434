.ReactModalPortal{
    z-index: 99999;
    position: relative;

    .dashboard-wrapper{
        height: 100%;
        &.firstLoginWrapper{
            position: relative;
            padding-left: 10%;
            padding-right: 10%;
            .container{
                display: flex;
                flex-direction: column;
                align-content: center;
                justify-content: center;
                text-align: center;
                width: 100%;
            }
            h1{
                font-size: 36px;
                margin: 0 0 30px 0;
            }
            input{
                margin: 0 auto;
                margin-top: 30px;
                //margin-bottom: 30px;
            }
            .stepFirstLogin{
                font-weight: 900;
                font-size: 24px;
                color: $primary-color;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
            }
            button{
                width: 15%;
                min-width: 100px;
                margin: 0 auto;
                &.logout{
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: auto;
                }

                &.skip{
                    position: absolute;
                    bottom: 0;
                    right: 115px;
                    width: auto;
                    background-color: $white;
                    color: $primary-color;

                    &:hover{
                        color: $primary-color-dark; 
                    }
                }
            }
            p{
                width: 70%;
                margin: 0 auto;
            }
            .imgContainer{
                img{
                    position: absolute;
                    top: 0px;
                    left: 0;
                    width: 12%;
                    min-width: 100px;
                }
            }
            .googleSignIn{
                width: 250px;
                margin-top: 30px;
            }
            .connectStripeBtn{
                width: 250px;
                margin-top: 30px;
            }
        }
    }
}