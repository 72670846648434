section.home {
  .itemProgressionWrapper {
    background-color: $primary-color;
    width: 100%;
    margin: 0 0 10px;
    box-sizing: border-box;
    border-radius: 10px;
    color: $white;
    position: relative;
    max-width : 600px;

    &:hover{
        background-color: $primary-color-dark;
    }

    a, button {
      color: $white;
      text-decoration: none;
      display: block;
      padding: 20px 100px 20px 20px;
      text-align: left;
      width: 100%;
      h3 { 
        margin: 0;
        font-size: 20px;
      }

      p {
        margin: 10px 0 0 0;
      }

      .checked-image,
      .circle {
        width: (256px / 7);
        height: (256px / 7);
        position: absolute;
        top: 50%;
        right: 40px;
        transform: translateY(-50%);
      }

      .circle {
        background-color: $white;
        border-radius: 50%;
        margin: 0;
      }
    }
  }
}

@media screen and (max-width: $mobile){
  section.home {
    .itemProgressionWrapper {
      a, button{
        h3{
          font-size : 15px;
          padding-right: 50px;
        }

        p{
          padding-right: 50px;
        }

        .checked-image{
          right: 20px;
        }

        .circle {
          right : 20px;
        }
      }
    }
  }
}
