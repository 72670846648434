footer{
    height     : 130px;
    width      : 100%;
    background : $white;
    overflow   : hidden;

    .wrapper{
        width      : 100%;
        height     : 100%;
        max-width  : 1920px;
        margin     : auto;
        display    : block;
        padding    : 0 100px;
        box-sizing : border-box;
        position   : relative;
        overflow   : hidden; 
    }


    .row:first-child{
        display         : flex;
        justify-content : space-between;
        align-items     : center;
        padding-top     : 40px;
    }

    .navigation-container{
        position: absolute;
        right: 100px;
    }

    .footer-navigation{
        background : $white;
        position   : relative;
        z-index    : 3;
        display : inline-block;
        vertical-align: top;
        line-height: 24px;

        /*
        &:before{
            content   : "";
            height    : 100%;
            width     : 9999px;
            display   : block;
            position  : absolute;
            right     : 100%;
            top       : 0; 
            z-index   : 3;
            background : $white; 
        }
        */

       ul{
           margin          : 0;
           list-style-type : none;
           padding         : 0;

           li{
                display : inline-block;
                margin  : 0 25px 0 0;

                &:last-child{
                    margin : 0 50px 0 0;
                }   

                a{
                    text-decoration : none;
                    color           : $black;
                    position        : relative;
                    font-size       : 14px;
                    text-transform  : capitalize;

                    &:after{
                        content    : "";
                        display    : block;
                        height     : 1px;
                        width      : 0;
                        position   : absolute;
                        background : $black;
                        bottom     : -1px;
                        left       : 0;
                        transition : width 0.3s ease;  

                    }

                    &:hover{
                        &:after{
                            width  : 100%; 
                        }
                    }
                }       
            }
        } 
    }

    .logo-container{
        position  : absolute;
        top       : 38px;
        left      : 50%;
        transform : translateX(-50%);

        &:before{
            content    : "";
            display    : block;
            height     : 4px;
            width      : 9999px;
            background : $black;  
            position   : absolute;
            right      : calc(100% - 2px);
            top        : 15px;
        }

        .logo{
            width : (477px / 2);
            height : (66px / 2); 
        }
    }

    .social-medias-container{
        display : inline-block;

        ul{
            margin  : 0;
            padding : 0;
            list-style-type : none;

            li{
                display : inline-block;
                margin  : 0 15px 0 0;

                &:last-child{
                    margin : 0;
                }  

                a{
                    width   : (47px / 2);
                    height  : (47px / 2);
                    display : block;
                    position: relative;
                     
                    img{
                        width  : (47px / 2);
                        height : (47px / 2);
                        transform-origin : center;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                    }
 
                    &:hover{
                        opacity : 0.7;
                    }
                }
            }
        }
    }

    .row:first-child{
        height : 68px; 
        box-sizing: border-box;
    }
    .row:last-child{
        padding-top : 30px; 
    }

    .copyright{
        text-align : center;
        font-size  : 12px;
        margin     : 0;
    }
}

@media screen and (max-width: 1450px){
    footer{
        .navigation-container{
            top: 40px;

            .footer-navigation{
                display: block;
                margin: 0 0 10px 0;

                ul li:last-child{
                    margin: 0;
                }
            }

            .social-medias-container{
                display: block;

                ul{
                    text-align: right;
                }
            }
        }
    }
}

@media screen and (max-width : $tablet){
    footer{
        height : 275px; 

            .wrapper{
                padding: 0 50px;
            }

            .logo-container{
                top : 150px;
            } 

            .row:last-child{
                padding-top: 85px;
            }

            .row:first-child{
                height: 125px;
            }

            .navigation-container{
                position: relative;
                right: auto;
                top: auto;
                margin: auto;
        
        
            .footer-navigation{
                background : transparent;

                &:before{
                    display : none;
                }

                ul li a:after{
                    display : none;
                }
            }

            .social-medias-container{
            
                ul{
                    text-align: center;

                    li{
                        margin : 0 5px 0 0;

                        a{
                            width   : 40px;
                            height  : 40px;
                            display : flex;
                            justify-content : flex-end; 
                            align-items     : center;

                            &:hover{
                                transform : inherit; 
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width : $mobile){
    footer{
        height : auto;

        .wrapper{
            padding: 0 25px;
        }

        .row:first-child{
            display : block;
            height: inherit;
        }

        .logo-container{
            bottom : 60px;
            top    : auto;
        }

        .footer-navigation{
            margin : 0 0 25px 0;

            ul{
                text-align : center;

                li{
                    margin : 0 0 15px 0;
                    display : block;

                    &:last-child{
                        margin : 0;
                    }
                }
            }
        }

        .social-medias-container{
            text-align : center;

            ul li a{
                justify-content : center; 
            }
        }

        .row:last-child{
            padding : 80px 0 25px 0;
        }
    }
}