section.categories {

  a.button{
    display : inline-block; 
    margin-right : 10px;
    margin-bottom: 10px;
    text-decoration: none;
    font-weight: normal;
    transition: none;
  }
}
