////////
//Typo//
////////
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");

$title-font-family: "Montserrat", sans-serif;
$text-font-family: "Montserrat", sans-serif;

/////////
//Color//
/////////
$primary-color: #000;
$primary-color-dark: #361e46;
$sec-color: #ece9e9;
$background-color: #ffffff;
$text-color: #000000; 

$white: #ffffff; 
$black: #000000;
$grey: #f0f0f0;
$dark-grey: #dfdfdf;   
$red: #ff0000; 
$green: #32cd32;

//////////
//Device//
//////////
$large: 1400px;
$tablet: 1280px;
$medium: 1000px;
$mobile: 767px;
$small: 550px;
