section.support {
  a.button {
    display: inline-block;
    font-weight: normal;
    width: inherit;
    text-decoration: none;
    &:after {
      display: none;
    }
  }

  .link-container{
    margin-bottom: 25px;
  }
}