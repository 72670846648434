section.myFlashes {
  a.button {
    display: inline-block;
    font-weight: normal;
    width: inherit;
    &:after {
      display: none;
    }
  }
  .flashItemContainer {
    margin: 0 0 50px 0;
    position: relative;

    width: 700px;
  }

  .delete-btn {
    position: absolute;
    top: 50%;
    right: 0;
    width: 40px;
    height: 40px;
    transform: translateY(-50%);
    background-color: $white;
    border: solid 1px $primary-color;

    svg {
      position: absolute;
      top: 10px;
      left: 10px;
      stroke: $primary-color;
    }

    &:hover{
      background-color: $primary-color-dark;

      svg {
        stroke: $white;
      }
    }
  }

  a {
    width: 100%;
    color: $black;
    box-sizing: border-box;
    padding: 0;
    text-align: left;
    cursor: pointer;
    text-decoration: none;
    vertical-align: top;
    position: relative;
    display: flex;
    align-items: center;

    &.sold {
      &:after {
        content: "View";
      }
    }

    h3 {
      color: $black;
      font-size: 18px;
      line-height: 21px;

      span {
        font-size: 12px;
        color: $red;
        margin: 0 10px 0 0;
        border: solid 1px $red;
        padding: 2px 4px;
        vertical-align: top;
      }
    }

    div.details-container {
      display: block;
      width: calc(100% - 400px);
      margin: 0 0 0 50px;
      padding: 0 150px 0 0;

      p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    div.image-container {
      position: relative;
      height: 200px;
      margin: 0 0 0 0;
      width: 200px;
      display: block;
      vertical-align: top;

      img {
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        max-width: 100%;
        max-height: 100%;
      }
    }

    .description-container {
      max-height: 150px;
      overflow: hidden;
      text-overflow: ellipsis;

      p {
        white-space: nowrap;
      }
    }

    &:after {
      content: "Edit";
      display: block;
      position: absolute;
      right: 50px;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
      vertical-align: middle;
      border-radius: 10px;
      background-color: $primary-color;
      color: $white;
      padding: 12px 15px;
    }

    &:hover {
      * {
        opacity: 0.75;
      }

      &:after {
        background-color: $primary-color-dark;
        color: $white;
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  section.myFlashes {
    a {
      width: 100%;
      max-width: 700px;
    }
  }
}

@media screen and (max-width: 800px) {
  section.myFlashes {

    .delete-btn{
      top : auto;
      bottom : 0;
      right : calc(50% - 30px);
      transform: translateX(50%);
    }

    .flashItemContainer{
      width : 100%;
      
    }

    a {
      flex-direction: column;
      max-width : inherit;
      width: auto;
      padding : 0 0 75px 0;

      div.details-container {
        margin: 0;
        padding: 0;
        text-align: center;
        width : 100%;

        h3{
          margin: 0 0 5px 0;
        }
      }

      div.image-container {
        margin : 0 0 25px 0;

      
      } 

      &:after{
        top : auto;
        bottom: 0;
        right : calc(50% + 30px);
        transform: translateX(50%);
      }
    }
  }
}
