.loading-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.9);
  z-index: 8000;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.5s linear;

  .loading-edge{
    font-size : 16px;
  }

  &.active {
    opacity: 1;
  }

  $animation-time: 3s;
  $easing: ease-in-out;

  #agenda-image {
    animation: rotate-agenda 3s linear infinite;
  }

  #agenda-svg {
    width: (315px / 6);
    height: (364px / 6);
    animation: rotate-agenda 3s linear infinite;

    .path {
      fill: none;
      stroke: #000;
      stroke-miterlimit: 10;
      stroke-width: 11px;
    }

    .agenda-base-path {
      stroke-dasharray: 1453;
      stroke-dashoffset: 1453;
      animation: trace-agenda $animation-time $easing infinite;
    }

    .rectangle-path {
      stroke-dasharray: 483;
      stroke-dashoffset: 483;
      animation: trace-rectangle $animation-time $easing infinite;
    }

    .line-1-path {
      stroke-dasharray: 114;
      stroke-dashoffset: 114;
      animation: trace-line-1 $animation-time $easing infinite;
    }

    .line-2-path {
      stroke-dasharray: 69;
      stroke-dashoffset: 69;
      animation: trace-line-2 $animation-time $easing infinite;
    }

    .line-3-path {
      stroke-dasharray: 300;
      stroke-dashoffset: 300;
      animation: trace-line-3 $animation-time $easing infinite;
    }

    .line-4-path {
      stroke-dasharray: 365;
      stroke-dashoffset: 365;
      animation: trace-line-4 $animation-time $easing infinite;
    }

    @keyframes rotate-agenda {
      0% {
        transform: rotate(-15deg);
      }
      100% {
        transform: rotate(15deg);
      }
    }

    @keyframes trace-agenda {
      0% {
        stroke-dashoffset: 1453;
      }
      40%,
      60% {
        stroke-dashoffset: 0;
      }
      100% {
        stroke-dashoffset: -1453;
      }
    }

    @keyframes trace-rectangle {
      0% {
        stroke-dashoffset: 483;
      }
      40%,
      60% {
        stroke-dashoffset: 0;
      }
      100% {
        stroke-dashoffset: -483;
      }
    }

    @keyframes trace-line-1 {
      0% {
        stroke-dashoffset: 114;
      }
      40%,
      60% {
        stroke-dashoffset: 0;
      }
      100% {
        stroke-dashoffset: -114;
      }
    }

    @keyframes trace-line-2 {
      0% {
        stroke-dashoffset: 69;
      }
      40%,
      60% {
        stroke-dashoffset: 0;
      }
      100% {
        stroke-dashoffset: -69;
      }
    }

    @keyframes trace-line-3 {
      0% {
        stroke-dashoffset: 300;
      }
      40%,
      60% {
        stroke-dashoffset: 0;
      }
      100% {
        stroke-dashoffset: -300;
      }
    }

    @keyframes trace-line-4 {
      0% {
        stroke-dashoffset: 365;
      }
      40%,
      60% {
        stroke-dashoffset: 0;
      }
      100% {
        stroke-dashoffset: -365;
      }
    }
  }

  .centerer {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
