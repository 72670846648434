/*Banner Page*/
section.banner-small {
  background-repeat: no-repeat;
  background-color: $grey;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 100px 0;
  background-size   : cover;
  background-position : center;  
  background-image: url('../../assets/images/sites/placeholders/banner-placeholder.png');

  h1 {
    color: $white;
    text-shadow: $black 0px 1px 4px;
    padding : 0 20px;
    text-align :center;
  }
}
     