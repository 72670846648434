section.product-details {
  padding: 100px 0 0 0;

  .wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
  }

  .image-container {
    width: 50%;
    text-align: center;
    font-weight: bold;

    img {
      max-width: 100%;
      max-height: 600px;
    }
  }

  .content-container {
    width: 50%;
    flex-grow: 1;
    position: relative;
    box-sizing: border-box;
    padding: 0 0 100px 100px;


    h1 {
      margin: 0 0 50px 0;
      font-size: 30px;
    }

    p.category {
      margin: -35px 0 35px;
    }

    .buttons-container {
      position: absolute;
      right: 0;
      bottom: 0;

      a.cancel {
        text-decoration: none;
      }

      .button:last-child {
        margin-left: 10px;
      }
    }

    &.content-details {
      .description-container {
        margin: 0 0 50px 0;
      }

      .details {
        display: flex;
        justify-content: space-around;
        align-content: flex-start;
        padding: 15px 0 20px 0;
        border-top: solid 6px $dark-grey;
        margin: 0 0 50px 0;
        background-color: #f5f5f5;
        width: 180px;

        .detail-item {
          display: flex;
          flex-direction: column;
        }

        .icon-container {
          display: flex;
          align-self: center;
          width: 60px;
          height: 60px;
          justify-content: center;
        }

        .icon {
          align-self: center;
          width: 100%;
        }

        .detail-title {
          align-self: center;
          font-size: 12px;
          margin: -4px 0 10px 0;
          font-family: "arial";
        }

        .detail-value {
          align-self: center;
          font-weight: 700;
          font-size: 15px;
          font-family: "arial";
        }
      }

      .small-chars {
        text-align: center;
        font-size: 12px;
        font-family: arial;
      }

      .terms-container {
        text-align: center;
      }

      a.terms {
        color: $black;
        text-decoration: none;
        font-weight: 700;
        font-family: "arial", sans-serif;
        font-size: 14px;

        &:hover {
          text-decoration: underline;
        }
      }
    }


    &.content-payment, &.content-shipping, &.content-confirmation { 
      .stripe-input-container {
        padding: 8px;
        border: 1px solid #ccc;
        margin-bottom: 10px;
        margin-top: 6px;
        border-radius: 5px;

        &.invalid {
          border-color: $red;
        }
      }

      input {
        font-size: 14px;
        font-family: "Arial";
        color: $black;
        padding: 8px;
        margin-bottom: 5px;
        border-radius: 5px;

        &.invalid {
          border-color: $red;
        }

        &.quantity-field{
          width: 45px;
        }
      }

      .radio-container{

        .radio-item{
          display: flex;
          align-items: center;
          margin-bottom: 10px;

        }
        input[type="radio"]{
          margin-right: 10px;
          margin-bottom: 0;
        }

        label{
          font-size: 14px;
          margin: 0;
          display: inline-block;
        }
      }

      p {
        font-size: 13px;
        margin: 0 0 5px 0;
      }

      .payment-subtitle {
        margin: 30px 0 10px 0;
        font-size: 14px;
      }

      .details-container {
        margin: 0 0 20px 0;
      }

      .terms-container {
        margin-top: 25px;

        a {
          font-size: 13px;
          margin: 0 0 5px 0;
          color: $black;
          display: inline-block;
          text-decoration: none;
          border-bottom: solid 1px $black;
          padding-bottom: 0;

          &:hover {
            border-bottom: none;
            padding-bottom: 1px;
          }
        }
      }
    }

    &.content-confirmation {
      a.button.dark {
        text-decoration: none;
      }
    }
  }

  .error-container {
    display: block;
    text-align: center;
    margin-bottom: -20px;
    margin-top: 15px;

    .error-wrapper {
      padding: 10px 10px 10px 10px;
      display: inline-flex;

      &:before {
        content: "!";
        background: $red;
        color: $white;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        vertical-align: bottom;
        padding: 7px;
        font-size: 20px;
        text-align: center;
        justify-content: center;
        align-self: center;
        display: flex;
        margin-right: 10px;
      }
    }

    .error {
      color: $red;
      text-align: center;
      font-weight: 700;
      font-size: 12px;
      font-family: arial;
      text-align: center;
      justify-content: center;
      align-self: center;
    }
  }
}

@media screen and (max-width: $tablet) {
  section.product-details {
    .image-container {
      width: 400px;
    }

    .content-container {
      width: calc(100% - 400px);
      padding: 0 0 100px 50px;

      h1 {
        margin: 0 0 40px 0;
      }

      &.content-details {
        .details {
          padding: 10px 0 15px 0;
          border-top: solid 5px $dark-grey;
          margin: 0 0 40px 0;

          .detail-title {
            margin: -4px 0 5px 0;
          }

          .detail-value {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $medium) {
  section.product-details {
    .image-container {
      width: 300px;
    }

    .content-container {
      width: calc(100% - 300px);

      h1 {
        margin: 0 0 25px 0;
        font-size: 25px;
      }

      &.content-details {
        .details {
          flex-direction: column;
          padding: 0;
          width: 100%;

          .detail-item {
            flex-direction: row;
            border-bottom: solid 1px white;

            &:last-child {
              border-bottom: none;
            }
          }

          .detail-title {
            margin: 0;
          }

          .detail-value { 
            position: absolute;
            right: 15px;
          }
        }
      }

    }
  }
}

@media screen and (max-width: $mobile) {
  section.product-details {
    padding: 50px 0 0 0;

    .wrapper {
      flex-direction: column;
    }

    .image-container {
      width: 100%;
      margin: 0 0 75px;

      img {
        max-height: 400px;
      }
    }

    .content-container {
      width: 100%; 
      padding: 0 0 100px 0;
    }
  }
}
 