////////
//Typo//
////////
@import url('https://fonts.googleapis.com/css?family=Montserrat');

$title-font-family: 'Montserrat', sans-serif;
$text-font-family: 'Montserrat', sans-serif;

/////////
//Color//
/////////
$primary-color: #4C2B62;
$primary-color-dark:#361E46;
$sec-color: #ECE9E9;
$background-color: #ffffff;
$text-color: #000000;

$white: #ffffff;
$black: #000000;

//////////
//Device//
//////////
$large  : 1400px;
$tablet : 1280px;
$medium : 1000px;
$mobile : 767px;
$small  : 500px;