////////
//Typo//
////////

* {
  font-family: $text-font-family;
}

.wysiwyg-content{
  a{
    color: $primary-color-dark;
  }

  ul, ol{
    padding-left: 20px;
  }
}

h1 {
  font-family: $title-font-family;
  font-size: 40px;
  margin: 0 0 15px 0;
  //font-weight: 400; 
}

h2 {
  font-family: $text-font-family;
  font-size: 20px;
  margin: 0 0 30px 0;
}

h3 {
  font-family: $text-font-family;
  font-size: 25px;
  margin: 0 0 15px 0;
}

h4 {
  font-family: $text-font-family;
  font-size: 18px;
  margin: 0 0 15px 0;
}

p, ul, ol {
  font-family: $text-font-family;
  font-size: 14px;
  margin: 0 0 20px;
  line-height: 18px;

  &:last-child {
    margin: 0;
  }
}

a {
  font-size: 14px;
}

/*
::selection {
  background-color: $primary-color;
}
*/

/*button*/
.button,
input[type="submit"] {
  background-color: $white;
  color: $black;
  padding: 12px 18px;
  border: 1px solid $black; 
  border-radius: 5px;
  cursor: pointer;
  transition: 420ms;
  font-size: 14px;
  font-weight: 700;

  &:hover {
    background: $black;
    color: $white;
  }

  &.dark {
    background-color: $black;
    color: $white;

  
    &:hover {
      background: $white;
      color: $black;
    }
  }

  &.back-to {
    text-decoration: none;
    margin-bottom: 25px;
    display: inline-block;
    padding: 8px 20px;

    &:hover {
      background: $white;
      color: $black;
    }
  }
}

main {
  position: relative;
  padding: 0 0 100px;
  box-sizing: border-box;
  min-height: calc(100vh - 230px);
}

/*Inputs*/
input[type="text"],
input[type="email"],
input[type="tel"],
select,
textarea {
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */
  border: 1px solid #ccc; /* Gray border */
  border-radius: 0px; /* Rounded borders */
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
}

.Dropdown-root{
    font-size: 14px;
    font-family: "Arial";
    padding: 8px;
    margin-bottom: 5px;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #ccc;
    box-sizing: border-box;
    margin-top: 6px;
    resize: vertical;
    position: relative;
    cursor: pointer;
    display: inline-block;

    &.invalid{
      border-color: $red;
    }

    &:after{
      display: block;
      content: '';
      width: 0; 
      height: 0; 
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid $primary-color;
      position: absolute;
      top: 14px;
      right: 10px;

    }

    .Dropdown-placeholder{
      color: #757575;
      font-family: "Arial";

      &.is-selected{
        color: $primary-color;
      }
    }

    .Dropdown-menu{
      margin-top: 10px;

      .Dropdown-option{
        font-family: "Arial";
        padding: 2px 0;
        cursor: pointer;

        &:hover{
          opacity: 0.5;
        }
      }
    }
    
} 

/* ----------------------------------------------------------------------- */

.wrapper {
  padding: 0 150px;
  max-width: 1280px;
  margin: auto;
}

@media screen and (max-width: $tablet) {
  .wrapper {
    padding: 0 100px;
  }

  main {
    min-height: calc(100vh - 300px);
  }
}

@media screen and (max-width: $medium) {
  .wrapper {
    padding: 0 40px;
  }

  h1 {
    font-size: 30px;
    margin: 0 0 15px 0;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 25px;
  }

  h4 {
    font-size: 18px;
  }
}

@media screen and (max-width: $mobile) {
  main {
    position: relative;
    padding: 0 0 60px;
    min-height: unset;
  }

  p { 
    margin: 0 0 25px;
  }
 
}
