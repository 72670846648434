header{
    height     : 100px;
    background : $white;

    .hamburger-scroll-prevent{
        display : none;
    }

    .wrapper{
        width      : 100%;
        height     : 100%;
        max-width  : 1920px;
        margin     : auto;
        display    : flex;
        padding    : 0 100px;
        box-sizing : border-box;
        justify-content : space-between;
        align-items     : center;
    }
    
    .logo-container{
        height  : 75px;
        width   : 250px;
        display : flex;
        justify-content : left; 
        align-items     : center; 

        .logo{
            max-height : 100%;
            max-width  : 100%;
        }
    }

    .main-navigation{
        ul{
            list-style-type : none;
            font-size       : 0;

            li{
                display        : inline-block;
                vertical-align : top;
                margin         : 0 25px 0 0;

                &:last-child{
                    margin : 0;
                }

                a{
                    text-decoration : none;
                    color           : $black;
                    position        : relative;
                    font-size       : 16px;
                    font-weight     : 400;
                    text-transform : capitalize; 
                 
                    &:after{
                        content    : "";
                        display    : block;
                        height     : 1px;
                        width      : 0;
                        position   : absolute;
                        background : $black;
                        bottom     : -1px;
                        left       : 0;
                        transition : width 0.3s ease;  

                    }

                    &:hover, &.active{
                        &:after{
                            width  : 100%;
                        }
                    }
                }

                &.social-item{
                    margin : -3px 15px 0 0;

                    &:last-child{
                        margin : -3px 0 0 0;
                    }

                    a{
                        display : block;
                        width   : (47px / 2);
                        height  : (47px / 2);

                        img{
                            width  : (47px / 2);
                            height : (47px / 2);  
                            transform-origin : center;
                        }

                        &:after{
                            display : none;
                        }

                        &:hover img{
                            opacity : 0.7; 
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width : $tablet){
    header{
    
        .wrapper{
            padding     : 0 50px;
            align-items : center;
        }

        .logo-container{
            //display : block;
            margin  : auto;
            justify-content: center;
        }

        .main-navigation{
            display : none;
        }

        .hamburger-scroll-prevent{
            display  : block;
            position : fixed;
            left     : 0;
            top      : 0;
            width    : 100%;
            height   : 0;
            z-index  : 9000;
    
            .hamburger-container{ 
                display  : block;
                position : absolute;
                width    : 50px;
                height   : 50px;
                right    : 25px;
                top      : 24px;

                button.hamburger-btn{
                    display       : block;
                    position      : absolute;
                    width         : 100%;
                    height        : 100%;
                    border-radius : 50%;
                    min-width     : auto;
                    background    : $white;
                    z-index       : 8;
                    border        : none;
                    cursor        : pointer;

                    figure{
                        height           : 2px;
                        width            : 25px;
                        border-radius    : 3px; 
                        position         : absolute;
                        transform-origin : center;
                        margin           : 0;
                        left             : 50%;
                        top              : 50%;
                        background       : $black;
                        transform        : translate(-50%);
                        transition       : all 0.2s ease;

                        &:first-child{
                            top : 34%;
                        }

                        &:last-child{
                            top : 66%;
                        }
                    }
                }

                .hamburger-nav-container{
                    position      : absolute;
                    top           : 50%;
                    left          : 50%;
                    border-radius : 50%;
                    transform     : translate(-50%,-50%);
                    transition    : all 0.3s ease-out;
                    width         : 0;
                    height        : 0;
                    overflow      : hidden;
                    z-index       : 6;
                    background    : $black;
                }
            }

            &.active{
                width    : 100%;
                height   : 100vh;
                overflow : hidden;
                position : fixed;

                .hamburger-container{ 
                    button.hamburger-btn{
                        figure{
                            &:first-child{
                                top       : 50%;
                                transform : translate(-50%) rotate(45deg);
                            }

                            &:nth-child(2){
                                opacity : 0;
                            }

                            &:last-child{
                                top       : 50%;
                                transform : translate(-50%) rotate(-45deg);
                            }
                        }
                    }
                }

                .hamburger-nav-container{
                    width      : 6000px;
                    height     : 6000px;
                    transition : all 0.5s ease-in;
                }

                nav.hamburger-nav{
                    opacity     : 1;
                    height      : 350px;
                    z-index     : 7;
                }
            }

            nav.hamburger-nav{
                opacity     : 0;
                color       : $white;
                position    : absolute;
                z-index     : 6;
                margin      : 0;
                text-align  : center;
                translate   : opacity 0.2s linear;
                height      : 0;
                width       : 250px;
                overflow    : hidden;
                padding     : 0;
                transition  : opacity 0.5s linear 0.1s, height 0.1s linear 0.1s;
                left        : calc(50% - 125px);
                top         : 80px;

                ul{
                    list-style-type : none;
                    margin          : 0;
                    padding         : 0;

                    li{
                        margin : 0 0 15px 0;

                        a{
                            color           : $white;
                            text-decoration : none;
                            font-size       : 19px;
                            text-transform: capitalize;
                        }

                        &.social-item{
                            display : inline-block;
                            margin  : 0 10px 0 0;

                            &:last-child{
                                margin : 0;
                            }

                            a{
                                display         : flex;
                                justify-content : center;
                                align-items     : center; 
                                width           : 40px;
                                height          : 40px;

                                img{
                                    width  : (47px / 2);
                                    height : (47px / 2);
                                }
                            }
                            
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width : $mobile){
    header{
        height : 75px;

        .logo-container{
            height  : 50px;
            width   : 200px;
        }

        .hamburger-scroll-prevent .hamburger-container{
            right : 14px;
            top   : 13px;
        }
    }
}