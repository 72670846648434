.backdrop{
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: $black;
  opacity: 0.5;
  z-index: 9999;
  display: none;

  &.active{
    display: block;
  }
}  

section.availability {
  .field-container{
    margin : 0 0 30px 0;

    button{
      display: inline-block;
    }
  }

  .subtitle{
    margin-bottom: 20px;
  }

  .info-container{
    display: inline-block;
    margin-right: 25px; 
    margin-bottom: 20px;

    p{
      margin: 0 0 10px;
    }
  }

  h3{
    font-size : 14px;
    margin: 0px 0 10px !important;
    color: $primary-color; 
  }

  .timepicker-modal{
    main{
      min-height: inherit;
    }

    footer{
      height: inherit;
      width: inherit;
      background: inherit;
      overflow: inherit; 
      display: block;
      width: 100%;
      box-sizing: border-box;

      button{
        display: inline-block;
      }
    }
  }

  /*
  .react-timekeeper__hour-select, .react-timekeeper__minute-select{
    color: $primary-color !important;
  }
  */
  .react-timekeeper-button-reset.react-timekeeper__meridiem-toggle{
    display: block;
    padding: 0;
    background-color: transparent;
    color: inherit;
    font-size: 13px;
  }


  .timekeeper-container{
    display: none;
 
    &.active{
      display: inline-block;
    }
  }

  .react-timekeeper{
    position: fixed !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%); 
    z-index: 99999;
  }

  .react-timerange-picker {
    .react-timerange-picker__wrapper {
      display: inline-block;
      border-bottom: solid 1px #ece8ef;
      padding-bottom : 30px;

      .react-timerange-picker__inputGroup {
        border: 1px solid #ccc;
        border-radius: 10px;
        display: inline-block;
        vertical-align: top;
        position :  relative;
        margin-top : 25px;
        margin-right : 10px;

        &:first-child{
          &:before{
              content : "From";
              display : block;
              position : absolute;
              top : -25px;
              left : 0;
              font-size : 14px;

          }
        }

        &:nth-child(3){
          &:before{
              content : "To";
              display : block;
              position : absolute;
              top : -25px;
              left : 0;
              font-size : 14px;
          }
        }
      }


    

      .react-timerange-picker__inputGroup__input {
        border: none;
        margin: 0;
        border-radius: 0;
        background-color: transparent;
        width : 65px !important; 
        display : inline-block;  
        
        &.react-timerange-picker__inputGroup__amPm{
        width: 75px !important;
        }
      }

      .react-timerange-picker__inputGroup__leadingZero {
        display: none;
      }

      .react-timerange-picker__range-divider{
        display : none;
      }
      

      .react-timerange-picker__clear-button {
        display: inline-block;
        vertical-align: top;
        width: 42px;
        height: 42px;
        padding: 12px 12px;
        margin-top : 25px;

        svg {
          stroke: $white;
        }
      }
    }
  }

  .error{
    h3{
      color: $red;
    }

    .react-timerange-picker .react-timerange-picker__wrapper .react-timerange-picker__inputGroup{
      border-color: $red;

      .react-time-picker__inputGroup__divider{
        color: $black;
      }
    }
  }
} 

@media screen and (max-width: $mobile){
  section.availability .react-timerange-picker .react-timerange-picker__wrapper .react-timerange-picker__inputGroup{
    margin-bottom: 15px;
  }
} 
