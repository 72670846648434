.dashboard-wrapper {
  display: flex;

  select {
    background-color: white;
    border: 1px solid #ccc;
    font-size: 14px;
    max-width: 90px;
    height: 42px;
    border-radius: 10px;

    &:last-child{
      margin: 0;
    }
  }

  .button,
  input[type="submit"] {
    font-weight: 400;
  }

  .error {
    .wysiwyg-container {
      .rdw-editor-toolbar {
        color: $black;
      }

      .rdw-editor-main {
        border: 1px solid red;
      }
    }
  }

  .wysiwyg-container {
    max-width: 680px;

    .rdw-option-wrapper[title="Strikethrough"],
    .rdw-option-wrapper[title="Monospace"],
    .rdw-option-wrapper[title="Superscript"],
    .rdw-option-wrapper[title="Subscript"],
    .rdw-option-wrapper[title="Indent"],
    .rdw-option-wrapper[title="Outdent"],
    .rdw-text-align-wrapper,
    .rdw-fontfamily-wrapper,
    .rdw-embedded-wrapper,
    .rdw-emoji-wrapper,
    .rdw-image-wrapper,
    .rdw-block-wrapper,
    .rdw-remove-wrapper,
    .rdw-fontsize-wrapper,
    .rdw-colorpicker-wrapper {
      display: none;
    }

    .rdw-link-modal-label,
    .rdw-link-modal-target-option span {
      font-weight: normal;
      font-size: 14px;
      margin: 0 0 10px 0;
    }

    #openLinkInNewWindow {
      display: inline-block;
      width: auto;
      margin: 0 10px 0 0;
    }

    .rdw-link-modal-input {
      margin: 0 0 15px 0;
    }

    .rdw-link-modal-btn {
      display: inline-block !important;
      padding: 0;

      &:disabled:hover {
        background: #ece9e9;
        box-shadow: none;
      }

      &:first-child {
        margin-left: 0;
      }
    }

    .rdw-editor-main {
      margin: 0 0 25px 0;
      font-size: 14px;
      border-radius: 10px;
      padding: 0 12px;
      border: 1px solid #ccc;
      box-sizing: border-box;
      width: 100%;
      display: block;
      min-height: 175px;
    }

    .rdw-editor-toolbar {
      border-radius: 10px;
      border-color: #ccc;
    }
  }

  .back-btn {
    text-decoration: none;
    margin: 0 0 50px 0;
    display: inline-block;
    opacity: 0.8;

    img {
      width: (128px / 6);
      height: (128px / 6);
      vertical-align: top;
      margin: 0 15px 0 0;
    }

    span {
      vertical-align: middle;
      line-height: (128px / 6);
      color: $primary-color;
      font-weight: bold;
    }
  }

  .dashboardInputCard {
    margin: 0 0 25px 0;
    font-size: 14px;
    border-radius: 10px;
    max-width: 300px;
    padding: 12px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    width: 100%;
    display: block;
  }

  .react-timerange-picker__inputGroup__input {
    width: 100px !important;
  }

  /*Typo*/
  h1 {
    font-size: 22px;
    margin: 0 0 50px 0;
  }

  h3 {
    margin: 50px 0 20px;

    &:first-child {
      margin: 0 0 20px;
    }
  }

  .userCreditCardList {
    display: flex;
    flex-direction: column;
    width: 300px;
    justify-content: space-between;
    margin: 0 0 50px 0;
    align-items: flex-start;
    position: relative;

    p {
      margin: 0 0 10px 0;
      white-space: nowrap;

      .title {
        font-weight: bold;
      }

      .brand {
        margin: 0 10px 0 0;
      }

      .dots {
        font-size: 23px;
        line-height: 14px;
      }
    }

    .delete-btn {
      position: absolute;
      left: calc(100% + 15px);
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
      background-color: $white;
      border: solid 1px $primary-color;

      svg {
        position: absolute;
        top: 10px;
        left: 10px;
        stroke: $primary-color;
      }

      &:hover {
        background-color: $primary-color-dark;

        svg {
          stroke: $white;
        }
      }
    }
  }

  .buttons-container button {
    display: inline-block;
    margin-right: 10px;
  }

  button,
  .button {
    display: block;
    padding: 12px 15px;
    background-color: $primary-color;
    color: $white;
    font-size: 14px;
    border-radius: 10px;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: $primary-color-dark;
    }

    &.prevent-click {
      background-color: $sec-color;
      color: grey;
      position: relative;
      cursor: default;
    }

    &.light {
      background-color: transparent;
      border: solid 1px $primary-color;
      color: $primary-color-dark;

      &:hover {
        background-color: $primary-color-dark;
        border: solid 1px $primary-color-dark;
        color: $white;
      }
    }
  }

  a.button {
    text-decoration: none;
    display: inline-block;
  }

  label,
  h2 {
    display: block;
    margin: 0 0 15px 0;
    font-weight: bold;
    font-size: 16px;

    &.upload-button {
      padding: 12px 15px;
      background-color: $primary-color;
      display: inline-block;
      border-radius: 10px;
      cursor: pointer;
      color: $white;
      font-weight: normal;
      font-size: 14px;
      margin: 0 10px 0 0;

      &:hover {
        background-color: $primary-color-dark;
      }
    }
  }

  .field-container label {
    font-size: 13px;
    opacity: 0.8;
    margin: 0 0 10px 0;
  }

  .image-upload-preview {
    display: none;
    width: 300px;
    margin: 0 0 25px 0;

    &.visible {
      display: block;
    }
  }

  input,
  textarea,
  .Dropdown-root,
  .input {
    margin: 0 0 25px 0;
    font-size: 14px;
    border-radius: 10px;
    max-width: 300px;
    padding: 12px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    width: 100%;
    display: block;

    &[type="number"]{
      max-width: 120px;
    }

    &.fileupload {
      display: none;
    }

    &:last-child {
      margin: 0;
    }

    &.invalid {
      border-color: red;
    }

    &:after {
      display: none;
    }

    &.currency{
      max-width: 70px;

      p, span{
        margin-bottom: 0;
      }
    }
  }

  textarea {
    max-width: 500px;
    min-height: 175px;
  }

  .radio-item,
  .checkbox-item {
    margin: 0 0 10px 0;

    input[type="radio"],
    input[type="checkbox"] {
      width: inherit;
      display: inline-block;
      vertical-align: top;
      margin: 0;
    }

    &.disabled{
      opacity: 0.6;
    }

    label.radio-label,
    label.checkbox-label {
      display: inline-block;
      vertical-align: top;
      font-size: 13px;
      font-weight: normal;
      margin: 0 0 0 15px;
    }

    .details {
      margin-top: 0px;
    }
  }

  .Dropdown-root {
    display: inline-block;
    padding: 0;

    &.is-open {
      .Dropdown-placeholder {
        &:after {
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: 6px solid $white;
          border-top: none;
        }
      }
    }

    .Dropdown-placeholder {
      font-size: 14px;
      vertical-align: middle;
      background-color: $white;
      text-align: left;
      color: $black;
      padding: 12px 45px 12px 15px;
      border-radius: 10px;
      cursor: pointer;
      position: relative;

      &:after {
        content: "";
        font-weight: bold;
        font-size: 16px;
        position: absolute;
        right: 17px;
        top: 19px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid $black;
      }
    }

    .Dropdown-menu {
      // background-color: #4c2b62;
      border-top: solid 1px #ccc;
      border-bottom: solid 1px #ccc;
      text-align: center;
      color: white;
      margin-top: 0;
      max-height: 200px;
      overflow-y: scroll;
      // border-radius: 10px;

      .Dropdown-option {
        color: $black;
        padding: 10px 15px;
        cursor: pointer;
        font-size: 13px;
        border-bottom: solid 1px #e1e1e1;
        text-align: left;

        &:hover {
          opacity: 0.6;
        }
      }
    }
  }

  .field-container {
    margin: 0 0 45px 0;
  }

  .input-container {
    margin-bottom: 25px;
  }

  .error {
    color: $red;
    margin: 0 0 25px 0;
    font-size: 14px;

    input,
    textarea {
      border-color: $red;
    }
  }
  /*//////////*/
  /*Navigation*/
  /*//////////*/

  /*
  .menu-offset {
    width: 258px;
  } 
  */

  .navigation {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
  }

  /*Navigation Main*/
  .nav-main-wrapper {
    height: 100vh;
    position: fixed;
    width: 75px;
    background-color: $sec-color;
    display: flex;
    justify-content: center;
    border-right: solid 3px #4c2b62;

    .nav-main {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;

      ul {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        li {
          transition: 420ms;

          &.active,
          &:hover {
            background-color: $white;
          }

          a {
            display: block;
            height: 75px;
            width: 75px;
            position: relative;
            border-bottom: solid 1px $white;
            overflow: hidden;

            img {
              position: absolute;
            }
          }

          .icon-dash {
            width: (78px / 2);
            height: (78px / 2);
            top: 17px;
            left: 18px;
          }

          .icon-avail {
            width: (82px / 2);
            height: (82px / 2);
            top: 17px;
            left: 18px;
          }

          .icon-page {
            width: (70px / 2);
            height: (70px / 2);
            top: 19px;
            left: 20px;
          }

          .icon-support {
            width: 45px;
            height: 45px;
            top: 15px;
            left: 15px;
          }

          .icon-settings {
            width: (72px / 2);
            height: (72px / 2);
            top: 19px;
            left: 19px;
          }

          .icon-products {
            width: (74px / 2);
            height: (74px / 2);
            top: 18px;
            left: 18px;
          }

          .icon-flashes {
            width: (138px / 2);
            height: (138px / 2);
            top: 4px;
            left: 3px;
          }
        }
        svg {
          fill: $primary-color;
          width: 30px;
          height: 30px;
          transition: 320ms;
        }
        svg:hover {
          fill: $primary-color-dark;
          cursor: pointer;
        }
      }
    }
  }

  /*Navigation sec*/
  .nav-sec-wrapper {
    margin-left: 78px;
    width: 180px;
    position: fixed;
    height: 100vh;
    background-color: $primary-color;
    display: flex;
    justify-content: center;

    .nav-sec {
      background-color: $primary-color;
      width: 100%;

      h1 {
        padding: 20px 20px;
        margin-bottom: 0;
        color: $white;
        cursor: context-menu;
        font-size: 22px;
      }
      ul {
        list-style-type: none;
        li {
          transition: 620ms;

          &.active {
            background-color: $primary-color-dark;
          }

          a {
            display: block;
            padding: 20px 20px;
            color: $white;
            text-decoration: none;
          }
        }
        li:hover {
          background-color: $primary-color-dark;
          cursor: pointer;
        }
      }
    }

    .navigation-overlay {
      display: none;
    }
  }

  /*/////////////////*/
  /*Dashboard Content*/
  /*/////////////////*/

  .dashboard-content-wrapper {
    margin-left: 258px;
    padding: 40px;
    width: 100%;
    box-sizing: border-box;
    /*Scroll bar*/

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .hamburger-btn {
    display: none;
  }

  p.details {
    margin: -10px 0 10px 0;
    color: #a9a9a9;
    font-size: 13px;
  }

  .columns{
    display: flex;
    margin-bottom: 20px;

    & > * {
      margin-right: 10px;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: $tablet) {
  .dashboard-wrapper {
    position: relative;

    label.upload-button {
      margin: 0 10px 10px 0;
    }

    .image-upload-preview {
      width: 100%;
      max-width: 300px;
    }

    .dashboard-content-wrapper {
      margin-left: 0;
      padding: 80px 30px 40px 30px;
    }

    .buttons-container {
      button {
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
    /*
    .menu-offset {
      width: 0;
      min-width: 0;
      transition: all 250ms; 
      &.active{
        width : 258px;
        min-width: 258px;
      }
    } 
    */

    .hamburger-btn {
      display: block;
      position: absolute;
      top: 25px;
      right: 25px;
      z-index: 9999;
      background: transparent;
      width: 40px;
      height: 40px;
      padding: 0;

      &:hover {
        background-color: transparent;
      }

      figure {
        width: 35px;
        height: 4px;
        background-color: $primary-color;
        left: 2px;
        top: 0;
        position: absolute;
        margin: 0;

        &:nth-child(1) {
          top: 8px;
        }

        &:nth-child(2) {
          top: 19px;
        }

        &:nth-child(3) {
          top: 30px;
        }
      }
    }
    .navigation {
      transition: transform 250ms;
      transform: translateX(-258px);

      &.active {
        transform: translateX(0);
      }
    }

    .navigation-overlay {
      display: block;
      background-color: #000;
      position: absolute;
      left: -200%;
      top: 0;
      z-index: 99;
      opacity: 0;
      width: 100%;
      height: 100%;
      min-height: 100vh;

      &.active {
        left: 0;
        opacity: 0.5;
        transition: opacity 200ms;
      }
    }
  }
}
